.intro-screen {
  display: flex;
  position: fixed;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
}

.intro-text {
  padding:0 5%;
}

.intro-text h1 {
  font-size: 4vh;
}

.intro-text h2 {
  font-size: 2.5vh;
  font-weight: 400;
  line-height: 1.5;
}

.topic-section-container {
  display: flex;
  flex-direction: row;
  width: 95vw;
  justify-content: space-evenly;
  object-fit: contain;

}

.topic-image {
  height: 22vw;
}

.topic-option-container {
  overflow: hidden;
  width: 16vw;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  /* margin-left:1%; */
  /* margin-right: 1%; */
}
.topic-option-container img {
  overflow: hidden;
}

.topic-button {
  display: flex;
  align-items: center;
  width: 16vw;
  height:4vw;
  justify-content: center;
  background-color: #d52b1e;
  color: white;
  border-radius: 5px;
  border: transparent solid 1px;
  margin-top: 10%;
  pointer-events: none;
}

#metagenomics img {
  rotate: 90deg;
  scale: 1.32;
  /* justify-self: center; */
  background-image: radial-gradient( circle farthest-corner at 0% 50%,
  #c3d3e5 10%,
  #7d9aaa 50%,
  #485463 95%,
  #03202f );
}

.topic-button h3 {
  font-weight: 400;
  font-size: 3.5vh;
}

.topic-option-container:hover > .topic-button {
  border: #d52b1e solid 1px;
  background-color: white;
  color: #d52b1e;
}

.topic-option-container :hover {
  cursor: pointer;
}
